<template>
  <div class="banners">
    <div class="banner-text">
      <div class="padL20">
        <p class="title">ธุรกิจอีคอมเมิร์ซ ERP ใช้ JSTERP</p>
        <div class="desc">
          <p>ทดลองใช้ JSTERP ฟรี ไม่จำกัดจำนวนผู้ใช้ สามารถตั้งค่า เปลี่ยนแปลงได้ตามต้องการ</p>
          <p>ใช้งานได้กับหลายแพลตฟอร์ม,จัดการหลากหลายร้านค้าได้ภายในระบบเดียว</p>
          <p>พร้อมด้วยฟังชั่นการจัดการมากมาย ภายในระบบ</p>
        </div>

        <el-button round @click="jump(JSTURL.createcompany,'TH')">ลงทะเบียน</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { setLanguageId } from '@/utils';
export default {
  name: 'Banners',
  methods: {
    jump(src, LanguageId) {
      setLanguageId(LanguageId);
      window.location.href = src;
    }
  }
};
</script>

<style lang="scss" scoped>
.banners {
  height: 560px;
  background: url("../../../assets/images/banner_erp.png") no-repeat center;
  position: relative;
  background-color: #02237c;
  overflow: hidden;
}

.banner-text {
  max-width: 1200px;
  margin: 180px auto;

  .title {
    color: #fff;
    font-size: 44px;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease-out, opacity 0.9s ease-out,
      -webkit-transform 0.5s ease-out;
  }

  .desc {
    color: #fff;
    padding: 12px 0 34px;
    line-height: 1.6em;
    max-width: 500px;
  }
}
</style>
