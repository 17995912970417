import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';
// 全局css
import './assets/css/base.css';
import './assets/css/common.css';
import './assets/css/iconfont/iconfont.css';
// 全局js
import URL_MANAGER from './assets/js/url_manager';
// 前端监控
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
if (location.hostname === 'www.jsterp.com') {
  Sentry.init({
    Vue,
    dsn: 'https://b4f51ca5bbb049138d703c7d8c4a591b@sentry.erp321.com/30',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

Vue.prototype.JSTURL = URL_MANAGER.URL_MANAGER;
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
