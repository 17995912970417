<template>
  <div class="banners">
    <div class="banner-text">
      <div class="padL20">
        <p class="title">JSTERP for e-commerce ERP</p>
        <div class="desc">
          <p>JSTERP Supports free trial, unlimited accounts, configuration on demand</p>
          <p>Support multi-platform, multi-store unified management, integrating multiple functions</p>
        </div>
        <el-button round @click="jump(JSTURL.createcompany,'EN')">Register now</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { setLanguageId } from '@/utils';
export default {
  name: 'Banners',
  methods: {
    jump(src, LanguageId) {
      setLanguageId(LanguageId);
      window.location.href = src;
    }
  }
};
</script>

<style lang="scss" scoped>
.banners {
  height: 560px;
  background: url("../../../assets/images/banner_erp.png") no-repeat center;
  position: relative;
  background-color: #02237c;
  overflow: hidden;
}

.banner-text {
  max-width: 1200px;
  margin: 180px auto;

  .title {
    color: #fff;
    font-size: 44px;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease-out, opacity 0.9s ease-out,
      -webkit-transform 0.5s ease-out;
  }

  .desc {
    color: #fff;
    padding: 12px 0 34px;
    line-height: 1.6em;
    max-width: 500px;
  }
}
</style>
