<template>
  <div id="app">
    <router-view />
    <div class="footer">
      This page is offered by Hong Kong Jushuitan Technology Co., Limited and its affiliates to provide services for users in China Hong Kong, Macao, Taiwan and Southeast Asia
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
    };
  },
  created() {
    this.getUserBrowserConfig();
  },
  mounted() {
    this.setScrollView();
  },
  methods: {
    // 根据浏览器语言显示文案
    getUserBrowserConfig() {
      const browser = {
        versions: (function() {
          var u = navigator.userAgent;
          return { // 移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
          };
        }())
        //, language: (navigator.browserLanguage || navigator.language).toLowerCase()
      };
      if (browser.versions.mobile || browser.versions.ios || browser.versions.android ||
          browser.versions.iPhone || browser.versions.iPad) {
        window.location.href = '/mp/';
      }
      // else {
      //   switch (browser.language) {
      //     case 'zh-cn':
      //       this.browser_lg = 'cn';
      //       break;
      //     case 'en':
      //       this.browser_lg = 'en';
      //       break;
      //     case 'ko-KR':
      //     case 'ko':
      //       this.browser_lg = 'kr';
      //       break;
      //     case 'th':
      //     case 'th-th':
      //       this.browser_lg = 'th';
      //       break;
      //     case 'es':
      //       this.browser_lg = 'es';
      //       break;
      //     case 'vi':
      //     case 'vi-vn':
      //       this.browser_lg = 'vn';
      //       break;
      //     case 'id':
      //       this.browser_lg = 'id';
      //       break;
      //     default:
      //       this.browser_lg = 'en';
      //   }
      //   const path = `/${this.browser_lg}`;
      //   if (this.$route.path !== path) {
      //     this.$router.push({ path, query: this.$route.query });
      //   }
      // }
    },
    setScrollView() {
      const view = this.$route.query.view;
      window.setTimeout(() => {
        if (view) {
          document.querySelector('#' + view).scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }
};
</script>

<style>
</style>
