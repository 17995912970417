<template>
  <div>
    <el-row class="contact">
      <el-col :span="12" style="padding-right:40px">
        <p class="country">{{ getCountry(0) }}：</p>
        <el-popover placement="top-start" width="200" trigger="hover" popper-class="mypop">
          <el-image
            style="width: 200px; height: 200px"
            :src="require('../../assets/images/ewm.png')"
            fit="fill"
          />
          <span slot="reference">
            <i class="iconfont icon-line" style="color:#00BA00" />
            <span>Line:@jsterpth</span>
          </span>
        </el-popover>
        <div class="marT10">
          <i class="iconfont icon-facebook" style="color:#117be5" />
          <el-link href="https://www.facebook.com/JSTERPthailand" target="_blank" class="facebook">
            JST ERP
            Thailand
          </el-link>
        </div>
        <div class="marT10">
          <i class="iconfont icon-dianhua" />
          <span>021020973</span>
        </div>
        <div class="marT10">
          <i class="iconfont icon-dizhi" />
          <span>36/75P.S tower bldg. 22fl. Sukhumvit 21, khlongtoey nua, watthana, bangkok 10110 thailand</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  props: {
    country: {
      type: String,
      default: 'cn'
    }
  },
  data() {
    return {
      CountryList: {
        cn: ['泰国', '菲律宾', '印度尼西亚', '越南'],
        en: ['Thailand', 'The Philippines', 'Indonesia', 'Vietnam'],
        th: ['เมืองไทย', 'ฟิลิปปินส์', 'ประเทศอินโดนีเซีย', 'เวียดนาม•'],
        vn: ['Thái', 'The Philippines', 'Indonesia', 'Việt Nam'],
        id: ['Thailand', 'Filipina', 'Indonesia', 'Vietnam']
      }
    };
  },
  computed: {
    getCountry() {
      return (index) => {
        return this.CountryList[this.country][index];
      };
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.el-link.el-link--default {
  color: #333;
  font-size: 16px;
}
.country {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.contact {
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;

  i {
    font-size: 20px;
    margin-right: 5px;
  }
}
.wx-box {
  position: absolute;
  left: 300px;
  top: 32px;
}
</style>
