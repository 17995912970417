<template>
  <div class="pages">
    <jst-header country="vn" />
    <el-main id="one">
      <jst-banner />
      <el-row id="second" class="panel">
        <h2 class="title">Lợi thế cốt lõi</h2>
        <el-row>
          <el-col :span="12">
            <div class="advantage yuan-box" style="border-bottom-width:1px;">
              <h4>Nhiều chức năng</h4>
              <p style="min-height:60px">
                JSTERP, hỗ trợ dùng thử miễn phí, không giới hạn số lượng người dùng.
                <br>Hỗ trợ quản lý thống nhất nhiều nền tảng, nhiều cửa hàng, và quản lý hàng tồn kho
                <br>Đối soát thông minh
              </p>
              <div class="advantage-menu">
                <span>Phân phối hiệu quả</span>
                <span>Quản lý đơn hàng</span>
                <span>Quản lý kho</span>
                <span>Quản lý sản phẩm</span>
                <span>Quản lý phân phối</span>
                <span>Quản lý báo cáo</span>
                <span>Quản lý chuỗi cung ứng</span>
                <span>Quản lý mua hàng</span>
                <span>Quản lý hàng tồn kho</span>
                <span>Quản lý sau bán</span>
                <span>Quản lý tài chính</span>
                <span>Quản lý khách hàng Kho của bên thứ ba</span>
              </div>
              <img src="../../assets/images/advantages_icon_01.png">
              <span class="yuan" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;border-bottom-width:1px">
              <h4>Hệ thống nhanh</h4>
              <p>
                Mười lăm năm trong ngành công nghệ, với trung bình hàng trăm triệu đơn đặt hàng mỗi ngày
                <br>Nền tảng ổn định để cung cấp cho khách hàng các dịch vụ hệ thống liên tục và ổn định
              </p>
              <div class="advantage-menu">
                <span>Nền tảng nâng cao</span>
                <span>Dịch vụ đám mây cấu hình cao</span>
                <span>Nâng cấp vô hạn</span>
              </div>
              <img src="../../assets/images/advantages_icon_02.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="height:280px">
              <h4>Dịch vụ tốt</h4>
              <p>
                Chúng tôi có một cơ chế dịch vụ hoàn thiện
                <br>Hệ thống chuyên nghiệp và dịch vụ lập kế hoạch tại chỗ
                <br>Dịch vụ khách hàng lâu dài
              </p>
              <div class="advantage-menu">
                <span>Dịch vụ từ đàu đến cuối</span>
                <span>Không bao giờ</span>
                <span>Dịch vụ bị gián đoạn</span>
              </div>
              <img src="../../assets/images/advantages_icon_03.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;height:280px">
              <h4>Tiết kiệm chi phí</h4>
              <p>
                Nâng cao hiệu quả quản lý kho hàng và giảm chi phí lao động
                <br>Nâng cao độ chính xác trong giao hàng, giảm chi phí do giao hàng sai
                <br>Nâng cao thông tin hoá quy trình và giảm chi phí tiêu hao
              </p>
              <div class="advantage-menu">
                <span>Giảm chi phí lao động</span>
                <span>Giao hàng sai và giảm chi phí tiêu hao</span>
                <span>Giảm chi phí vật tư tiêu hao</span>
              </div>
              <img src="../../assets/images/advantages_icon_04.png">
            </div>
          </el-col>
        </el-row>
      </el-row>
      <el-row id="third" class="panel">
        <h2 class="title">Sản phẩm của chúng tôi</h2>
        <el-row class="product-menu">
          <div class="list">
            <p>
              <i class="iconfont icon-dingdan" />
            </p>
            <p class="name">Quản lý đơn hàng</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-shangpin" />
            </p>
            <p class="name">Quản lý sản phẩm</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-caigou1" />
            </p>
            <p class="name">Quản lý mua hàng</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-jingxiao" />
            </p>
            <p class="name">Quản lý kho</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kefu3" />
            </p>
            <p class="name">Quản lý sau bán</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-disanfangcangku" />
            </p>
            <p class="name">Quản lý kho ba bên</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kucun1" />
            </p>
            <p class="name">Quản lý hàng tồn kho</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-baobiao1" />
            </p>
            <p class="name">Quản lý báo cáo</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-PDApandian" />
            </p>
            <p class="name">PDA cầm tay</p>
          </div>
        </el-row>
      </el-row>
      <el-row class="product-features-box">
        <el-row class="panel">
          <h2 class="title Ffff">Các chức năng cốt lõi</h2>
          <el-tabs
            v-model="activeName"
            @tab-click="tabClick"
            @mouseover.native="mouseOver"
            @mouseleave.native="mouseLeave"
          >
            <el-tab-pane label="Phân phối hiệu quả(OMS)" name="1">
              <dl>
                <dt>Phân phối hiệu quả</dt>
                <dd>● Đồng bộ hóa đơn hàng, dễ dàng quản lý nhiều nền tảng</dd>
                <dd>● Đối soát hàng loạt đơn hàng, xác định thông minh các đơn đặt hàng có dấu hiệu bất thường</dd>
                <dd>● Cập nhật đồng bộ và sửa đổi đơn đặt hàng, xử lý kịp thời các dấu hiệu bất thường</dd>
                <dd>● Tự động chia tách và hợp nhất các đơn đặt hàng</dd>
                <dd>● Phân phối quà tặng</dd>
                <dd>● Kết hợp với chuyển phát nhanh</dd>
              </dl>
              <img class="product-features-img" src="../../assets/images/oms_vn.png">
            </el-tab-pane>
            <el-tab-pane label="Quản lý kho(WMS)" name="2">
              <dl>
                <dt>Quản lý kho</dt>
                <dd>● Đồng bộ kịp thời, đa nền tảng, quản lý tồn kho của nhiều cửa hàng khác nhau</dd>
                <dd>● Quản lý chi tiết hàng tồn kho, thông báo ngay khi lượng tồn kho xuống quá thấp</dd>
                <dd>● Quản lý nhập kho cụ thể và kịp thời</dd>
                <dd>● Sử dụng máy cầm tay PDA thay cho thao tác thủ công</dd>
                <dd>● Tối ưu hoá công việc tìm hàng</dd>
                <dd>● Hỗ trợ đánh giá hiệu quả công việc của nhân viên</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/wms_vn.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
            <el-tab-pane label="Đa nền tảng" name="3">
              <dl>
                <dt>Đa nền tảng</dt>
                <dd>● Kết nối với nhiều nền tảng thương mại điện tử nổi tiếng như lazada, shopee, tokopedia, shopify, v.v., tất cả các đơn hàng đều được xử lý thống nhất để nâng cao hiệu quả</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/dpt.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-row>
      <el-row class="panel" style="margin-top: 50px;">
        <div>
          <el-image :src="require('../../assets/images/gz.png')" style="width:750px;height:407px;" />
          <dl class="product-con" style="padding-top:0px;margin-top:-50px">
            <p>Nhiều chức năng thông minh để tăng tốc độ xử lý đơn hàng</p>
            <dt>Quy tắc bộ lọc thông minh</dt>
            <dd>Sau khi đơn đặt hàng được tải xuống, có thể phối hợp nhiều loại quy tắc, trước tin tiền hàng phân loại đơn hàng, thuận tiện để chỉnh sửa và quản lý</dd>
            <dt>Lựa chọn thông minh các quy tắc vận chuyển</dt>
            <dd>Phối hợp với các quy tắc vận chuyển, đối soát thông minh với nhà cung cấp dịch vụ hậu cần một cách tối ưu</dd>
            <dt>Quy tắc phân kho thông minh</dt>
            <dd>Hệ thống có thể dựa trên sản phẩm để phân kho, phân khu vực để phát hàng</dd>
            <dt>Quy tắc quà tặng</dt>
            <dd>Người bán dựa theo quy tắc hoạt động, AI dựa trên đơn đặt hàng để tự động tặng phần quà tương ứng, nâng cao sự hài lòng của khách hàng.</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>Nhiều chiến lược tìm hàng để nâng cao hoạt động lưu kho</p>
            <dd>Đồng thời có thể sử dụng nhiều phương pháp tìm hàng</dd>
            <dd>Tối ưu hóa cấu trúc đơn hàng</dd>
            <dd>Tối đa hóa hiệu quả công việc</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/cc.png')"
            style="width:750px;height:407px; "
          />
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/bb.png')" style="width:750px;height:407px;" />
          <dl class="product-con">
            <p>Phân tích hoạt động chính xác của các báo cáo đa chiều</p>
            <dd>Cung cấp báo cáo về sản phẩm, đơn đặt hàng và cửa hàng để nâng cao hoạt động chính</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>Hỗ trợ đầy đủ chức năng PDA</p>
            <dd>Cung cấp hỗ trợ hoạt động PDA đầy đủ để cải thiện độ chính xác của hoạt động</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/pda.png')"
            style="width:750px;height:407px;"
          />
        </div>
      </el-row>
      <el-row class="service-progress">
        <div class="container">
          <h2 class="title">Quá trình dịch vụ</h2>
          <div class="service-progress-img">
            <img src="../../assets/images/process.png" alt width="100%">
          </div>
          <div class="service-progress-content">
            <div class="item item-1">
              <p>Giao tiếp đầu vào</p>
              <p>
                Khảo sát tại chỗ về các mô hình kinh doanh
                <br>và mô hình kinh doanh của khách hàng
              </p>
            </div>
            <div class="item item-2">
              <p>Phát triển kế hoạch tổng thể</p>
              <p>
                Lập kế hoạch và chuyển đổi kho hàng,
                <br>quy trình tiêu chuẩn, mua sắm phần cứng,
                <br>lập kế hoạch tiến độ thực hiện, v.v.
              </p>
            </div>
            <div class="item item-3">
              <p>Có nhân viên hướng dẫn</p>
              <p>
                Đào tạo thống nhất cho các bộ phận
                <br>liên quan liên quan đến hệ thống ERP
              </p>
            </div>
            <div class="item item-4">
              <p>Khởi tạo và sắp xếp dữ liệu</p>
              <p>
                Sắp xếp tài khoản, di chuyển dữ liệu
                <br>lịch sử và đảm bảo tính nhất quán của dữ liệu
              </p>
            </div>
            <div class="item item-5">
              <p>Quản lý hợp nhất kho</p>
              <p>
                Kiểm kê hàng tồn kho, sắp xếp kho
                <br>hàng hợp lý theo quy trình
              </p>
            </div>
            <div class="item item-6">
              <p>Thiết kế và điều chỉnh quy trình</p>
              <p>
                Thiết bị phần cứng và kiểm tra quy
                <br>trình chức năng tổng thể của hệ thống
              </p>
            </div>
            <div class="item item-7">
              <p>Chạy thử trực tuyến</p>
              <p>
                Thương lượng thời điểm thích hợp để
                <br>chuyển đổi hệ thống để đảm bảo quá
                <br>trình chuyển đổi suôn sẻ
              </p>
            </div>
            <div class="item item-8">
              <p>Quy trình củng cố và vận hành chính thức</p>
              <p>
                Điều chỉnh giải pháp và xác nhận hoạt
                <br>động chính thức của quy trình nghiệp vụ
                <br>tiêu chuẩn cuối cùng
              </p>
            </div>
          </div>
        </div>
      </el-row>
      <el-row id="fourth" class="panel">
        <h2 class="title">Liên hệ chúng tôi</h2>
        <contact-us country="th" />
      </el-row>
    </el-main>
    <!-- <el-footer class="j-footer" height="auto">
      <div class="panel" style="padding:20px 0">
        <div class="foot-con">
          <div class="foot-con-header">management</div>
          <p>Company name: Shanghai Shangyi Network Technology Co., Ltd</p>
          <p>Address: Room 161, 1st Floor, Building 2, No. 68, Guangming Central Road, Zhuangxing Town, Fengxian District, Shanghai</p>
          <p>Advertising license: 91310120MA1HMDJL2N</p>
        </div>
      </div>
    </el-footer> -->
  </div>
</template>

<script>
import jstBanner from './moudel/banners';
import contactUs from '../../component/contactUs';
import jstHeader from '../../component/header';
export default {
  name: 'App',
  components: {
    jstBanner, contactUs, jstHeader
  },
  data() {
    return {
      activeName: '1',
      activeIndex: 'one'
    };
  },
  mounted() {
    this.timer = setInterval(this.croeFunction, 5000);
  },
  methods: {
    mouseOver() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseLeave() {
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    croeFunction() {
      let num = Number(this.activeName) + 1;
      if (num > 3) {
        num = 1;
      }
      this.activeName = String(num);
    },
    tabClick() {
      clearInterval(this.timer);
      this.timer = null;
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        this.$router.push({
          path: `/${i.split('-')[1]}`
        });
        return false;
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.product-features-img {
  width: 748px;
  height: auto;
}

.pages {
  min-height: 100%;
  overflow: hidden;

  h2.title {
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 55px;

    &::after {
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 2px;
      width: 64px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
        no-repeat center center;
    }
  }

  .header-bj {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06),
      0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 0 4px -1px rgba(0, 0, 0, 0.08);

    .header {
      width: 1200px;
      padding: 10px 20px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 auto;

      .menu {
        display: flex;
        align-items: center;

        /deep/ .el-menu--horizontal {
          > .el-menu-item {
            font-weight: 400;
            height: 50px;
            line-height: 50px;

            &:hover {
              color: #2263e6 !important;
            }
          }

          > .el-submenu {
            .el-submenu__title {
              height: 50px;
              line-height: 50px;
            }
          }
        }

        .el-menu.el-menu--horizontal {
          border-color: transparent;
        }
      }
    }
  }

  .panel {
    width: 1200px;
    padding-top: 60px;
    margin: 0 auto;

    .advantage {
      background: #fff;
      padding: 40px 20px 0;
      height: 320px;
      box-sizing: border-box;
      border: 0 solid #f2f4f5;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease;

      &:hover {
        -webkit-box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      &.yuan-box {
        z-index: 2;

        .yuan {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #d8d8d8;
          position: absolute;
          right: -3px;
          bottom: -3px;
          z-index: 2;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        line-height: 20px;
        color: #999;
        margin-top: 16px;
        min-height: 80px;
      }

      .advantage-menu {
        background: #f9f9f9;
        min-height: 68px;
        color: #01a2f7;
        padding: 10px 18px;
        line-height: 24px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 22px;
        }
      }

      img {
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    .product-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        width: 150px;
        min-height: 90px;
        text-align: center;
        padding: 14px 0;

        &:nth-child(2n) {
          background: #eef7ff;
        }

        i {
          font-size: 26px;
          color: #2263e6;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        line-height: 18px;
      }
    }

    .country {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .contact {
      font-size: 16px;
      margin-bottom: 24px;
      color: #333;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .product-features-box {
    background-image: url("../../assets/images/product_features.b141f.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0d2796;
    height: 752px;
    margin-top: 20px;

    .el-tab-pane {
      border: 1px solid #657bd5;
      border-radius: 5px;
      background: rgba(101, 123, 213, 0.3);
      min-height: 426px;
      margin: 0 18px;
      padding-top: 34px;
      box-sizing: border-box;
    }
  }

  .service-progress {
    background: url("../../assets/images/process_bg.47772.png") no-repeat center;
    background-size: cover;
    padding-bottom: 132px;
    overflow: hidden;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      padding: 80px 18px 0;

      .block-cap {
        text-align: center;
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
          font-weight: normal;
          color: #333;
          margin: 70px 0;

          &::after {
            content: "";
            display: block;
            margin: 10px auto 0;
            height: 2px;
            width: 64px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
              no-repeat center center;
          }
        }
      }

      .service-progress-img {
        margin-top: 42px;
      }

      .service-progress-content {
        position: relative;

        .item {
          position: absolute;
          text-align: center;

          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4 {
            top: -310px;
            left: 0;
          }

          &.item-5,
          &.item-6,
          &.item-7,
          &.item-8 {
            top: 10px;
            left: 982px;
          }

          &.item-5 {
            left: 1002px;
          }

          &.item-2 {
            left: 298px;
          }

          &.item-3 {
            left: 668px;
          }

          &.item-4 {
            left: 974px;
          }

          &.item-6 {
            left: 672px;
          }

          &.item-7 {
            left: 318px;
          }

          &.item-8 {
            left: -28px;
          }

          p:first-child {
            font-size: 16px;
            color: #117be5;
            line-height: 26px;
            font-weight: bold;
          }

          p:last-child {
            font-size: 14px;
            color: #666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.product-con {
  width: 450px;
  padding-top: 40px;

  p {
    font-size: 26px;
    color: #2263e6;
    margin-bottom: 30px;
    line-height: 30px;
  }

  dt {
    margin-top: 10px;
    font-size: 16px;
    color: #2263e6;
    margin-bottom: 0px;
  }

  dd {
    font-size: 14px;
    color: #666;
  }
}

dl {
  vertical-align: top;
  width: 400px;
  padding-left: 20px;
  padding-right: 0px;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  dt {
    font-size: 30px;
    margin-bottom: 26px;
    color: #fff;
  }

  dd {
    line-height: 1.6em;
    padding: 6px 0;
    font-size: 14px;
    color: #cbcbcb;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__nav-scroll {
      overflow: hidden;
    }

    .el-tabs__item {
      font-size: 20px;
      color: #fff;
      height: 66px;
      padding: 0;

      &::after {
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
      }

      &.is-active::after {
        border-bottom: 8px solid #657bd5;
      }
    }
  }
}
</style>
