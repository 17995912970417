import Vue from 'vue';
import VueRouter from 'vue-router';
import en from '../views/en/index';
import cn from '../views/cn/index';
import th from '../views/th/index';
import vn from '../views/vn/index';
import id from '../views/id/index';
import none from '../views/404/index';

Vue.use(VueRouter);
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/',
    name: 'en',
    component: en
  }, {
    path: '/en',
    name: 'en',
    component: en
  }, {
    path: '/cn',
    name: 'cn',
    component: cn
  }, {
    path: '/th',
    name: 'th',
    component: th
  }, {
    path: '/vn',
    name: 'vn',
    component: vn
  }, {
    path: '/id',
    name: 'id',
    component: id
  }, {
    path: '/none',
    name: 'none',
    component: none
  },
  {
    path: '*',
    redirect: '/none'
  }];
const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;

