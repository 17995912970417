<template>
  <div class="pages">
    <jst-header country="th" />
    <el-main id="one">
      <jst-banner />
      <el-row id="second" class="panel">
        <h2 class="title">ฟังชั่นการใช้งาน</h2>
        <el-row>
          <el-col :span="12">
            <div class="advantage yuan-box" style="border-bottom-width:1px;">
              <h4>
                ระบบช่วยจัดการธุรกิจแบบ ครบวงจร
                <br>ตั้งแต่รับออเดอร์ จัดการคลังสินค้า จนสินส่งสินค้า
              </h4>
              <p style="min-height:60px">
                เพื่อการจัดการที่ง่ายขึ้นของคุณ
                ให้ระบบของเราช่วยจัดการธุรกิจให้ไหลลื่นไปได้โดยง่าย
                <br>ด้วยระบบอัจฉริยะ ที่เข้ามาช่วยคุณบริหารได้แบบครบวงจร ภายในระบบเดียว
                <br>และยังสามารถใช้ได้กับร้านค้าออนไลน์ชื่อดังหลายร้าน เช่น shopee lazada taobao
              </p>
              <div class="advantage-menu">
                <span>JSTERP สามารถจัดการให้คุณในเรื่องเหล่านี้ได้!</span>
                <span>จัดการคำสั่งซื้อ การจัดการคลังสินค้า การจัดการสินค้า การบริหารจัดการการจัดจำหน่าย</span>
                <span>รายงานการจัดการ การจัดการห่วงโซ่อุปทาน การบริหารการจัดซื้อ การจัดการสินค้าคงคลัง</span>
                <span>การจัดการหลังการขาย การจัดการทางการเงิน การจัดการลูกค้า คลังสินค้าบุคคลที่สาม</span>
                <span>ทั้งหมดนี้จัดการได้ในระบบเดียวด้วย JSTERP</span>
              </div>
              <img src="../../assets/images/advantages_icon_01.png">
              <span class="yuan" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;border-bottom-width:1px">
              <h4>จัดการทุกอย่าง ได้รวดเร็ว!</h4>
              <p>
                เทคโนโลยีได้รับการพัฒนากว่า 15ปีโดยมีการตรวจสอบคำสั่งซื้อเฉลี่ยต่อวันกว่าร้อยล้านฉบับ
                <br>รูปแบบการใช้งานที่มั่นคง ช่วยลูกค้าได้รับบริการระบบที่มีความเสถียรอย่างต่อเนื่อง
              </p>
              <div class="advantage-menu">
                <span>รูปแบบการทำงานขั้นสูง</span>
                <span>กำหนดค่าขั้นสูงด้วยบริการระบบคลาวด์</span>
                <span>การอัปเดตอัตโนมัติ</span>
              </div>
              <img src="../../assets/images/advantages_icon_02.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="height:280px">
              <h4>ลูกค้า มาก่อนเสมอ</h4>
              <p>
                เรามีกลไกที่สมบูรณ์แบบสำหรับการใช้บริการ
                <br>ระบบมืออาชีพและบริการการวางแผนเว็บไซต์
                <br>บริการดูแลลูกค้าระยะยาว
              </p>
              <div class="advantage-menu">
                <span>บริการตั้งแต่เริ่มจนจบ</span>
                <span>สอนใช้ระบบจนใช้เป็นจริง</span>
                <span>บริการลูกค้าอย่างต่อเนื่อง</span>
              </div>
              <img src="../../assets/images/advantages_icon_03.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;height:280px">
              <h4>ประหยัดต้นทุน</h4>
              <p>
                เพิ่มประสิทธิภาพการดำเนินงานของคลังสินค้าและลดต้นทุนแรงงาน
                <br>เพิ่มความถูกต้องของการจัดส่งและลดค่าใช้จ่ายจากการส่งผิด
                <br>เพิ่มประสิทธิภาพของข้อมูลและลดต้นทุนวัสดุ
              </p>
              <div class="advantage-menu">
                <span>ลดต้นทุนแรงงาน</span>
                <span>ลดการส่งของขาด-ผิด ไม่ขาดทุนเพิ่ม</span>
                <span>ลดต้นทุนสินค้าอุปโภค</span>
              </div>
              <img src="../../assets/images/advantages_icon_04.png">
            </div>
          </el-col>
        </el-row>
      </el-row>
      <el-row id="third" class="panel">
        <h2 class="title">ผลิตภัณฑ์ของเรา</h2>
        <el-row class="product-menu">
          <div class="list">
            <p>
              <i class="iconfont icon-dingdan" />
            </p>
            <p class="name">คำสั่ง</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-shangpin" />
            </p>
            <p class="name">การจัดการสินค้า</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-caigou1" />
            </p>
            <p class="name">การจัดการการจัดซื้อ</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-jingxiao" />
            </p>
            <p class="name">การจัดการคลังสินค้า</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kefu3" />
            </p>
            <p class="name">การจัดการหลังการขาย</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-disanfangcangku" />
            </p>
            <p class="name">การจัดการคลังสินค้าที่สาม</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kucun1" />
            </p>
            <p class="name">การจัดการสินค้าคงคลัง</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-baobiao1" />
            </p>
            <p class="name">การจัดการรายงาน</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-PDApandian" />
            </p>
            <p class="name">เครื่อง PDA</p>
          </div>
        </el-row>
      </el-row>
      <el-row class="product-features-box">
        <el-row class="panel">
          <h2 class="title Ffff">หน้าที่หลัก</h2>
          <el-tabs
            v-model="activeName"
            @tab-click="tabClick"
            @mouseover.native="mouseOver"
            @mouseleave.native="mouseLeave"
          >
            <el-tab-pane label="การจัดการการสั่งซื้อ(OMS)" name="1">
              <dl>
                <dt>หลายแพลตฟอร์ม</dt>
                <dd>● จัดการประสานงานคำสั่งซื้อหลายแพลตฟอร์มที่สะดวกง่ายดาย</dd>
                <dd>● การตรวจสอบคำสั่งแบบอัจฉริยะที่ระบุคำสั่งผิดปกติ</dd>
                <dd>● ระบบเชื่อมต่อปรับปรุงคำสั่งและสกัดกั้นข้อผิดพลาดทันเวลา</dd>
                <dd>● แยกและรวมคำสั่งอัตโนมัติ</dd>
                <dd>● การให้ของแถม</dd>
                <dd>● ระบบจับคู่ขนส่งอัจฉริยะ</dd>
              </dl>
              <img class="product-features-img" src="../../assets/images/oms_th.png">
            </el-tab-pane>
            <el-tab-pane label="การจัดการคลังสินค้า(WMS)" name="2">
              <dl>
                <dt>การจัดการคลังสินค้า</dt>
                <dd>● หลากหลายแพลตฟอร์ม หลากหลายร้านค้า ก็ซิงค์กันได้</dd>
                <dd>● จัดการจำนวนอย่างแม่นยำ ไม่มีการจำหน่ายสินค้าเกิน</dd>
                <dd>● นำสินค้าเข้าคลังทันทีในคลิ๊กเดียว</dd>
                <dd>● การจัดการโดย PDA ไร้กระดาษตั้งแต่ต้นจนจบ</dd>
                <dd>● แนวทางการหยินสินค้าดีเยี่ยม</dd>
                <dd>● รองรับการตรวจสอบการทำงานของพนักงาน</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/wms_th.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
            <el-tab-pane label="หลายแพลตฟอร์ม" name="3">
              <dl>
                <dt>หลายแพลตฟอร์ม</dt>
                <dd>
                  ● เชื่อมต่อกับ lazada,shopee,tokopedia,shopify หลากหลายแพล็ตฟอร์ม
                  จัดการคำสั่งซื้อได้อย่างมีมีประสิทธิภาพยิ่งขึ้น
                </dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/dpt.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-row>
      <el-row class="panel" style="margin-top: 50px;">
        <div>
          <el-image :src="require('../../assets/images/gz.png')" style="width:750px;height:407px;" />
          <dl class="product-con" style="padding-top:0px;margin-top:-50px">
            <p>
              เครื่องมืออัจริยะ
              <br>เพื่อเพิ่มความเร็วในการประมวลผลคำสั่ง
            </p>
            <dt>เครื่องมือกรองอัจฉริยะ</dt>
            <dd>
              เมื่อมีใบสั่งสินค้าเข้ามา เราสามารถตั้งค่าในการจัดการ
              <br>ใบสินค้า ให้เป็นหมวดหมู่ และตรวจสอบได้
            </dd>
            <dt>เครื่องมือตั้งค่าการขนส่ง</dt>
            <dd>ตั้ั้งค่าจับคู่การขนส่ง ให้การทำงานรวดเร็ว มีประสิทธิภาพยิ่งขึ้น</dd>
            <dt>ดั้งค่าการจัดการโกดัง</dt>
            <dd>ระบบสามารถจัดการแบ่งชนิดประเภท แบ่งขอบเขตโกดัง รวมไปถึงแบ่งโกดังสำหรับการจัดส่ง</dd>
            <dt>เครื่องมือ แถมสินค้า</dt>
            <dd>
              สามารถตั้งค่าตามโปรโมชั่นของร้านในขณะนั้น และตั้งเป้าราคา
              <br>เพื่อให้ของแถม
              เพิ่มความพึงพอใจของลูกค้าได้
            </dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>
              ระบบหยิบสินค้าจากหลายหลายโกดัง
              <br>เพื่อการทำงานที่รวดเร็ว
            </p>
            <dd>สามารถตั้งค่าการหยิบสินค้าได้หลากหลายในเวลาเดียวกัน</dd>
            <dd>พร้อมเพิ่งประสิทธิภาพการจัดการใบสั่งซื้อ</dd>
            <dd>“ลดความผิดพลาด เพิ่มโอกาสสร้างกำไร!”</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/cc.png')"
            style="width:750px;height:407px; "
          />
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/bb.png')" style="width:750px;height:407px;" />
          <dl class="product-con">
            <p>วิเคราะห์การทำงานของข้อมูลในร้านค้า</p>
            <dd>
              นำข้อมูลของร้านค้าที่มี มาประมวลผล และรายงานกับผู้ใช้
              <br>เพื่อการวางแผนในอนาคตที่มีประสิทธิภาพมากขึ้น
            </dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>การทำงานจากเครื่อง PDA เต็มรูปแบบ</p>
            <dd>
              รองรับการจัดการจาก PDA ทั้งหมด
              <br>เพื่อความแม่นยำในการดำเนินการ
            </dd>
          </dl>
          <el-image
            :src="require('../../assets/images/pda.png')"
            style="width:750px;height:407px;"
          />
        </div>
      </el-row>
      <el-row class="service-progress">
        <div class="container">
          <h2 class="title">การดำเนินงานของเรา</h2>
          <div class="service-progress-img">
            <img src="../../assets/images/process.png" alt width="100%">
          </div>
          <div class="service-progress-content">
            <div class="item item-1">
              <p>เจรจา พบปะ พูดคุย</p>
              <p>
                ไปที่โกดังของลูกค้า หรือโชว์รูมของเรา
                <br>เพื่อสำรวจรูปแบบธุรกิจ
              </p>
            </div>
            <div class="item item-2">
              <p>กำหนดรูปแบบการจัดการ</p>
              <p>
                ปรับรูปแบบการจัดการโกดัง การดำเนินงานขั้นต้น
                <br>จัดซื้ออุปกรณ์ และดำเนินการตั้งระบบ
              </p>
            </div>
            <div class="item item-3">
              <p>ดำเนินการอบรมการใช้ระบบ</p>
              <p>
                อบรมการใช้ระบบERP
                <br>ให้พนักงานในองค์กร
              </p>
            </div>
            <div class="item item-4">
              <p>เริ่มการจัดระเบียบข้อมูล</p>
              <p>
                จัดระเบียบข้อมูลสมาชิก
                <br>โอนถ่ายและเก็บข้อมูลให้อย่างครบถ้วน
              </p>
            </div>
            <div class="item item-5">
              <p>จัดระเบียบคลังสินค้า</p>
              <p>
                จัดการสินค้าคงคลัง วางแผน
                <br>และจัดคลังสินค้า
              </p>
            </div>
            <div class="item item-6">
              <p>ติดตั้งและทดลองดำเนินงาน</p>
              <p>
                ติดตั้้งอุปกรณ์และทดลองการ
                <br>ดำเนินงานตั้งแต่ต้นจนจบ
              </p>
            </div>
            <div class="item item-7">
              <p>เริ่มทำงานออนไลน์</p>
              <p>
                เมื่อระบบคงที่ จะปรับเปลี่ยนไปเป็นระบบออนไลน์
                <br>เพื่อการดำเนินงานที่ประสิทธิภาพมากขึ้น
              </p>
            </div>
            <div class="item item-8">
              <p>เริ่มดำเนินการอย่างเป็นทางการ</p>
              <p>
                ปรับเปลี่ยนการดำเนินงาน
                <br>ดำเนินรูปแบบระบบที่เหมาะสมที่สุดสำหรับลูกค้า
              </p>
            </div>
          </div>
        </div>
      </el-row>
      <el-row id="fourth" class="panel">
        <h2 class="title">ติดต่อเรา</h2>
        <contact-us country="th" />
      </el-row>
    </el-main>
    <!-- <el-footer class="j-footer" height="auto">
      <div class="panel" style="padding:20px 0">
        <div class="foot-con">
          <div class="foot-con-header">management</div>
          <p>Company name: Shanghai Shangyi Network Technology Co., Ltd</p>
          <p>Address: Room 161, 1st Floor, Building 2, No. 68, Guangming Central Road, Zhuangxing Town, Fengxian District, Shanghai</p>
          <p>Advertising license: 91310120MA1HMDJL2N</p>
        </div>
      </div>
    </el-footer> -->
  </div>
</template>

<script>
import jstBanner from './moudel/banners';
import contactUs from '../../component/contactUs';
import jstHeader from '../../component/header';
export default {
  name: 'App',
  components: {
    jstBanner, contactUs, jstHeader
  },
  data() {
    return {
      activeName: '1',
      activeIndex: 'one'
    };
  },
  mounted() {
    this.timer = setInterval(this.croeFunction, 5000);
  },
  methods: {
    mouseOver() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseLeave() {
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    croeFunction() {
      let num = Number(this.activeName) + 1;
      if (num > 3) {
        num = 1;
      }
      this.activeName = String(num);
    },
    tabClick() {
      clearInterval(this.timer);
      this.timer = null;
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        this.$router.push({
          path: `/${i.split('-')[1]}`
        });
        return false;
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.el-link.el-link--default {
  color: #333;
  font-size: 16px;
}
.product-features-img {
  width: 748px;
  height: auto;
}

.pages {
  min-height: 100%;
  overflow: hidden;

  h2.title {
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 55px;

    &::after {
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 2px;
      width: 64px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
        no-repeat center center;
    }
  }

  .header-bj {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06),
      0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 0 4px -1px rgba(0, 0, 0, 0.08);

    .header {
      width: 1200px;
      padding: 10px 20px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 auto;

      .menu {
        display: flex;
        align-items: center;

        /deep/ .el-menu--horizontal {
          > .el-menu-item {
            font-weight: 400;
            height: 50px;
            line-height: 50px;

            &:hover {
              color: #2263e6 !important;
            }
          }

          > .el-submenu {
            .el-submenu__title {
              height: 50px;
              line-height: 50px;
            }
          }
        }

        .el-menu.el-menu--horizontal {
          border-color: transparent;
        }
      }
    }
  }

  .panel {
    width: 1200px;
    padding-top: 60px;
    margin: 0 auto;

    .advantage {
      background: #fff;
      padding: 40px 20px 0;
      height: 320px;
      box-sizing: border-box;
      border: 0 solid #f2f4f5;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease;

      &:hover {
        -webkit-box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      &.yuan-box {
        z-index: 2;

        .yuan {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #d8d8d8;
          position: absolute;
          right: -3px;
          bottom: -3px;
          z-index: 2;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        line-height: 20px;
        color: #999;
        margin-top: 16px;
        min-height: 80px;
      }

      .advantage-menu {
        background: #f9f9f9;
        min-height: 68px;
        color: #01a2f7;
        padding: 10px 18px;
        line-height: 24px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 22px;
        }
      }

      img {
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    .product-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        width: 150px;
        min-height: 90px;
        text-align: center;
        padding: 14px 0;

        &:nth-child(2n) {
          background: #eef7ff;
        }

        i {
          font-size: 26px;
          color: #2263e6;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        line-height: 18px;
      }
    }
  }

  .product-features-box {
    background-image: url("../../assets/images/product_features.b141f.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0d2796;
    height: 752px;
    margin-top: 20px;

    .el-tab-pane {
      border: 1px solid #657bd5;
      border-radius: 5px;
      background: rgba(101, 123, 213, 0.3);
      min-height: 426px;
      margin: 0 18px;
      padding-top: 34px;
      box-sizing: border-box;
    }
  }

  .service-progress {
    background: url("../../assets/images/process_bg.47772.png") no-repeat center;
    background-size: cover;
    padding-bottom: 132px;
    overflow: hidden;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      padding: 80px 18px 0;

      .block-cap {
        text-align: center;
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
          font-weight: normal;
          color: #333;
          margin: 70px 0;

          &::after {
            content: "";
            display: block;
            margin: 10px auto 0;
            height: 2px;
            width: 64px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
              no-repeat center center;
          }
        }
      }

      .service-progress-img {
        margin-top: 42px;
      }

      .service-progress-content {
        position: relative;

        .item {
          position: absolute;
          text-align: center;

          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4 {
            top: -310px;
            left: 0;
          }

          &.item-5,
          &.item-6,
          &.item-7,
          &.item-8 {
            top: 10px;
            left: 982px;
          }

          &.item-5 {
            left: 1002px;
          }

          &.item-2 {
            left: 298px;
          }

          &.item-3 {
            left: 668px;
          }

          &.item-4 {
            left: 974px;
          }

          &.item-6 {
            left: 672px;
          }

          &.item-7 {
            left: 308px;
          }

          &.item-8 {
            left: -28px;
          }

          p:first-child {
            font-size: 16px;
            color: #117be5;
            line-height: 26px;
            font-weight: bold;
          }

          p:last-child {
            font-size: 14px;
            color: #666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.product-con {
  width: 450px;
  padding-top: 40px;

  p {
    font-size: 26px;
    color: #2263e6;
    margin-bottom: 30px;
    line-height: 30px;
  }

  dt {
    margin-top: 10px;
    font-size: 16px;
    color: #2263e6;
    margin-bottom: 0px;
  }

  dd {
    font-size: 14px;
    color: #666;
  }
}

dl {
  vertical-align: top;
  width: 400px;
  padding-left: 20px;
  padding-right: 0px;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  dt {
    font-size: 30px;
    margin-bottom: 26px;
    color: #fff;
  }

  dd {
    line-height: 1.6em;
    padding: 6px 0;
    font-size: 14px;
    color: #cbcbcb;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__nav-scroll {
      overflow: hidden;
    }

    .el-tabs__item {
      font-size: 20px;
      color: #fff;
      height: 66px;
      padding: 0;

      &::after {
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
      }

      &.is-active::after {
        border-bottom: 8px solid #657bd5;
      }
    }
  }
}
</style>

<style>
.el-popover.mypop {
  padding: 0;
}
</style>
