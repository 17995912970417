<template>
  <div class="pages">
    <jst-header country="id" />
    <el-main id="one">
      <jst-banner />
      <el-row id="second" class="panel">
        <h2 class="title">Nilai kompetitif</h2>
        <el-row>
          <el-col :span="12">
            <div class="advantage yuan-box" style="border-bottom-width:1px;">
              <h4>Multi - fungsi</h4>
              <p style="min-height:60px">
                JSTERP medukung uji coba gratis, akun user tidak terbatas
                <br>Mendukung lintas platform dan banyak toko, manajemen inventaris terpadu,
                <br>Periksa pesanan dengan cerdas, Distribusi yang efisien, Manajemen pesanan
              </p>
              <div class="advantage-menu">
                <span>pesanan</span>
                <span>gudang</span>
                <span>manajemen produk</span>
                <span>distribusi</span>
                <span>laporan</span>
                <span>manajemen vendor</span>
                <span>manajemen pembelian</span>
                <span>Inventaris / stok barang</span>
                <span>Manajemen pasca-jual</span>
                <span>Manajemen keuangan</span>
                <span>Manajemen konsumen</span>
                <span>gudang pihak ketiga</span>
              </div>
              <img src="../../assets/images/advantages_icon_01.png">
              <span class="yuan" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;border-bottom-width:1px">
              <h4>Sistem cepat</h4>
              <p>
                Pengembangan selama lima belas tahun lamanya, dengan verifikasi harian rata-rata mencapai ratusan juta pesanan
                <br>penerapan Kerangka kerja yang stabil untuk menyediakan layanan sistem yang berkelanjutan dan stabil kepada pelanggan
              </p>
              <div class="advantage-menu">
                <span>Kerangka lanjutan</span>
                <span>Layanan cloud konfigurasi tinggi</span>
                <span>Tidak ada peningkatan pada sistem</span>
              </div>
              <img src="../../assets/images/advantages_icon_02.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="height:280px">
              <h4>Pelayanan yang baik</h4>
              <p>
                Kami memiliki mekanisme implementasi layanan yang sempurna
                <br>Pelayanan jangka panjang untuk pelanggan
              </p>
              <div class="advantage-menu">
                <span>Pelayanan dari front-end sampai back-end</span>
                <span>Memandu pengguna hingga menguasai</span>
                <span>Layanan pelanggan di tempat tanpa gangguan</span>
              </div>
              <img src="../../assets/images/advantages_icon_03.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;height:280px">
              <h4>Memotong biaya</h4>
              <p>
                Meningkatkan efektivitas gudang dan mengurangi biaya pekerja
                <br>Meningkatkan akurasi pengiriman, memangkas biaya salah pengiriman dan biaya lainnya
                <br>Tingkat informatisasi ditingkatkan, dan biaya bahan habis pakai dipangkas
              </p>
              <div class="advantage-menu">
                <span>Mengurangi biaya pekerja</span>
                <span>Memangkas biaya salah pengiriman dan biaya lainnya</span>
                <span>Mengurangi biaya bahan habis pakai</span>
              </div>
              <img src="../../assets/images/advantages_icon_04.png">
            </div>
          </el-col>
        </el-row>
      </el-row>
      <el-row id="third" class="panel">
        <h2 class="title">Produk kami</h2>
        <el-row class="product-menu">
          <div class="list">
            <p>
              <i class="iconfont icon-dingdan" />
            </p>
            <p class="name">Manajemen pesanan</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-shangpin" />
            </p>
            <p class="name">Manajemen produk</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-caigou1" />
            </p>
            <p class="name">Manajemen pembelian</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-jingxiao" />
            </p>
            <p class="name">Manajemen gudang</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kefu3" />
            </p>
            <p class="name">Manajemen pasca-jual</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-disanfangcangku" />
            </p>
            <p class="name">Manajemen gudang pihak ketiga</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kucun1" />
            </p>
            <p class="name">Manajemen Inventaris</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-baobiao1" />
            </p>
            <p class="name">Manajemen laporan</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-PDApandian" />
            </p>
            <p class="name">PDA alat pemindai</p>
          </div>
        </el-row>
      </el-row>
      <el-row class="product-features-box">
        <el-row class="panel">
          <h2 class="title Ffff">Fungsi dasar</h2>
          <el-tabs
            v-model="activeName"
            @tab-click="tabClick"
            @mouseover.native="mouseOver"
            @mouseleave.native="mouseLeave"
          >
            <el-tab-pane label="Manajemen pesanan(OMS)" name="1">
              <dl>
                <dt>Banyak platform</dt>
                <dd>● Sinkronisasi pesanan pada Waktu aktual, pengaturan mudah pada setiap platform</dd>
                <dd>● Ulasan pesanan massal, identifikasi pintar pada pesanan janggal</dd>
                <dd>● Sinkronisasi pesanan terbaru dan modifikasi pesanan, mencegah pengecualian secara tepat waktu</dd>
                <dd>● Pisahkan dan gabungkan pesanan secara otomatis</dd>
                <dd>● Pengiriman hadiah</dd>
                <dd>● Pencocokan cerdas logistik</dd>
              </dl>
              <img class="product-features-img" src="../../assets/images/oms_id.png">
            </el-tab-pane>
            <el-tab-pane label="manajemen produk(WMS)" name="2">
              <dl>
                <dt>Manajemen gudang</dt>
                <dd>● Banyak platform, banyak toko inventaris, sinkronisasi tepat waktu</dd>
                <dd>● Persediaan inventaris yang tepat, peringatan stok habis dan tidak terjual melebihi inventaris</dd>
                <dd>● Sekali melihat inventaris masuk langsung jelas</dd>
                <dd>● alat pemindai PDA operasi tanpa kertas</dd>
                <dd>● jalur pengambilan barang yang optimal</dd>
                <dd>● Mendukung penilaian kinerja karyawan</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/wms_id.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
            <el-tab-pane label="Banyak platform" name="3">
              <dl>
                <dt>Banyak platform</dt>
                <dd>● Docking dengan beberapa platform e-commerce terkenal, seperti lazada, shopee, tokopedia, shopify. Semua pesanan diproses secara seragam untuk meningkatkan efisiensi</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/dpt.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-row>
      <el-row class="panel" style="margin-top: 50px;">
        <div>
          <el-image :src="require('../../assets/images/gz.png')" style="width:750px;height:407px;" />
          <dl class="product-con" style="padding-top:0px;margin-top:-50px">
            <p>Beberapa aturan cerdas untuk mempercepat pemrosesan pesanan</p>
            <dt>Aturan penyaringan cerdas</dt>
            <dd>Setelah pesanan diunduh, berbagai aturan dapat dikonfigurasi untuk mengkategorikan pesanan terlebih dahulu untuk memfasilitasi modifikasi dan tinjauan massal berikutnya</dd>
            <dt>Pemilihan cerdas pada aturan logistik</dt>
            <dd>Berdasarkan aturan pencocokan logistik, secara cerdas mencocokan penyedia logistik yang optimal</dd>
            <dt>Aturan sub-penyimpanan cerdas</dt>
            <dd>Sistem secara cerdas dapat mencocokkan gudang pengiriman sesuai dengan distribusi barang dan distribusi regional</dd>
            <dt>Aturan hadiah</dt>
            <dd>Sesuai aturan aktivitas, pedagang dengan cerdas memberikan hadiah yang sesuai dengan tujuan untuk meningkatkan kepuasan pelanggan</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>Berbagai strategi pengambilan barang untuk mencapai operasi gudang yang efisien</p>
            <dd>Tentukan beberapa strategi pengambilan secara bersamaan</dd>
            <dd>Optimisasi struktur pesanan</dd>
            <dd>Memaksimalkan efektivitas kinerja</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/cc.png')"
            style="width:750px;height:407px; "
          />
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/bb.png')" style="width:750px;height:407px;" />
          <dl class="product-con">
            <p>Analisis operasi yang akurat dari laporan multi-dimensi</p>
            <dd>Memberikan laporan tentang dimensi produk, pesanan, dan toko untuk meningkatkan operasi utama</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>Fungsi dukungan PDA selama proses</p>
            <dd>Memberikan dukungan operasi PDA selama proses berlangsung untuk meningkatkan akurasi operasi</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/pda.png')"
            style="width:750px;height:407px;"
          />
        </div>
      </el-row>
      <el-row class="service-progress">
        <div class="container">
          <h2 class="title">Proses layanan</h2>
          <div class="service-progress-img">
            <img src="../../assets/images/process.png" alt width="100%">
          </div>
          <div class="service-progress-content">
            <div class="item item-1">
              <p>GKomunikasi di tempat</p>
              <p>
                Investigasi di tempat terhadap pola
                <br>bisnis pelanggan dan model bisnis
              </p>
            </div>
            <div class="item item-2">
              <p>merumuskan rencana keseluruhan</p>
              <p>
                Perencanaan dan transformasi pergudangan,
                <br>pengadaan perangkat keras,
                <br>perencanaan jadwal implementasi, dll.
              </p>
            </div>
            <div class="item item-3">
              <p>Pelatihan karyawan</p>
              <p>
                Pelatihan terpadu untuk departemen
                <br>terkait yang melibatkan sistem ERP
              </p>
            </div>
            <div class="item item-4">
              <p>Inisialisasi dan pengurutan data</p>
              <p>
                Sortir akun, migrasikan data historis,
                <br>dan pastikan konsistensi data
              </p>
            </div>
            <div class="item item-5">
              <p>konsolidasi ruang kosong gudang</p>
              <p>
                Pemeriksaan inventaris,
                <br>atur gudang secara wajar sesuai dengan perencanaan proses
              </p>
            </div>
            <div class="item item-6">
              <p>Peralatan dan proses debugging</p>
              <p>
                Peralatan perangkat keras dan uji proses
                <br>fungsi sistem secara keseluruhan
              </p>
            </div>
            <div class="item item-7">
              <p>Uji coba online</p>
              <p>
                Negosiasikan waktu yang tepat dan alihkan
                <br>sistem untuk memastikan transisi yang lancar
              </p>
            </div>
            <div class="item item-8">
              <p>Pemadatan proses dan operasi formal</p>
              <p>
                Sesuaikan solusi dan lakukan konfirmasi operasi
                <br>formal pada langkah akhir proses bisnis
              </p>
            </div>
          </div>
        </div>
      </el-row>
      <el-row id="fourth" class="panel">
        <h2 class="title">Hubungi kami</h2>
        <contact-us country="id" />
      </el-row>
    </el-main>
    <!-- <el-footer class="j-footer" height="auto">
      <div class="panel" style="padding:20px 0">
        <div class="foot-con">
          <div class="foot-con-header">management</div>
          <p>Company name: Shanghai Shangyi Network Technology Co., Ltd</p>
          <p>Address: Room 161, 1st Floor, Building 2, No. 68, Guangming Central Road, Zhuangxing Town, Fengxian District, Shanghai</p>
          <p>Advertising license: 91310120MA1HMDJL2N</p>
        </div>
      </div>
    </el-footer> -->
  </div>
</template>

<script>
import jstBanner from './moudel/banners';
import contactUs from '../../component/contactUs';
import jstHeader from '../../component/header';
export default {
  name: 'App',
  components: {
    jstBanner, contactUs, jstHeader
  },
  data() {
    return {
      activeName: '1',
      activeIndex: 'one'
    };
  },
  mounted() {
    this.timer = setInterval(this.croeFunction, 5000);
  },
  methods: {
    mouseOver() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseLeave() {
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    croeFunction() {
      let num = Number(this.activeName) + 1;
      if (num > 3) {
        num = 1;
      }
      this.activeName = String(num);
    },
    tabClick() {
      clearInterval(this.timer);
      this.timer = null;
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        this.$router.push({
          path: `/${i.split('-')[1]}`
        });
        return false;
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.product-features-img {
  width: 748px;
  height: auto;
}

.pages {
  min-height: 100%;
  overflow: hidden;

  h2.title {
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 55px;

    &::after {
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 2px;
      width: 64px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
        no-repeat center center;
    }
  }

  .header-bj {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06),
      0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 0 4px -1px rgba(0, 0, 0, 0.08);

    .header {
      width: 1200px;
      padding: 10px 20px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 auto;

      .menu {
        display: flex;
        align-items: center;

        /deep/ .el-menu--horizontal {
          > .el-menu-item {
            font-weight: 400;
            height: 50px;
            line-height: 50px;

            &:hover {
              color: #2263e6 !important;
            }
          }

          > .el-submenu {
            .el-submenu__title {
              height: 50px;
              line-height: 50px;
            }
          }
        }

        .el-menu.el-menu--horizontal {
          border-color: transparent;
        }
      }
    }
  }

  .panel {
    width: 1200px;
    padding-top: 60px;
    margin: 0 auto;

    .advantage {
      background: #fff;
      padding: 40px 20px 0;
      height: 320px;
      box-sizing: border-box;
      border: 0 solid #f2f4f5;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease;

      &:hover {
        -webkit-box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      &.yuan-box {
        z-index: 2;

        .yuan {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #d8d8d8;
          position: absolute;
          right: -3px;
          bottom: -3px;
          z-index: 2;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        line-height: 20px;
        color: #999;
        margin-top: 16px;
        min-height: 80px;
      }

      .advantage-menu {
        background: #f9f9f9;
        min-height: 68px;
        color: #01a2f7;
        padding: 10px 18px;
        line-height: 24px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 22px;
        }
      }

      img {
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    .product-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        width: 150px;
        min-height: 90px;
        text-align: center;
        padding: 14px 0;

        &:nth-child(2n) {
          background: #eef7ff;
        }

        i {
          font-size: 26px;
          color: #2263e6;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        line-height: 18px;
      }
    }

    .country {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .contact {
      font-size: 16px;
      margin-bottom: 24px;
      color: #333;

      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .product-features-box {
    background-image: url("../../assets/images/product_features.b141f.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0d2796;
    height: 752px;
    margin-top: 20px;

    .el-tab-pane {
      border: 1px solid #657bd5;
      border-radius: 5px;
      background: rgba(101, 123, 213, 0.3);
      min-height: 426px;
      margin: 0 18px;
      padding-top: 34px;
      box-sizing: border-box;
    }
  }

  .service-progress {
    background: url("../../assets/images/process_bg.47772.png") no-repeat center;
    background-size: cover;
    padding-bottom: 132px;
    overflow: hidden;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      padding: 80px 18px 0;

      .block-cap {
        text-align: center;
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
          font-weight: normal;
          color: #333;
          margin: 70px 0;

          &::after {
            content: "";
            display: block;
            margin: 10px auto 0;
            height: 2px;
            width: 64px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
              no-repeat center center;
          }
        }
      }

      .service-progress-img {
        margin-top: 42px;
      }

      .service-progress-content {
        position: relative;

        .item {
          position: absolute;
          text-align: center;
          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4 {
            top: -310px;
            left: 0;
          }

          &.item-5,
          &.item-6,
          &.item-7,
          &.item-8 {
            top: 10px;
            left: 982px;
          }

          &.item-5 {
            left: 955px;
            width: 280px;
          }

          &.item-2 {
            left: 282px;
          }

          &.item-3 {
            left: 642px;
          }

          &.item-4 {
            width: 260px;
            left: 952px;
          }

          &.item-6 {
            left: 622px;
          }

          &.item-7 {
            left: 286px;
          }

          &.item-8 {
            left: -50px;
          }

          p:first-child {
            font-size: 16px;
            color: #117be5;
            line-height: 26px;
            font-weight: bold;
          }

          p:last-child {
            font-size: 14px;
            color: #666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.product-con {
  width: 450px;
  padding-top: 40px;

  p {
    font-size: 26px;
    color: #2263e6;
    margin-bottom: 30px;
    line-height: 30px;
  }

  dt {
    margin-top: 10px;
    font-size: 16px;
    color: #2263e6;
    margin-bottom: 0px;
  }

  dd {
    font-size: 14px;
    color: #666;
  }
}

dl {
  vertical-align: top;
  width: 400px;
  padding-left: 20px;
  padding-right: 0px;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  dt {
    font-size: 30px;
    margin-bottom: 26px;
    color: #fff;
  }

  dd {
    line-height: 1.6em;
    padding: 6px 0;
    font-size: 14px;
    color: #cbcbcb;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__nav-scroll {
      overflow: hidden;
    }

    .el-tabs__item {
      font-size: 20px;
      color: #fff;
      height: 66px;
      padding: 0;

      &::after {
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
      }

      &.is-active::after {
        border-bottom: 8px solid #657bd5;
      }
    }
  }
}
</style>
