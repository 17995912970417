<template>
  <el-header class="header-bj" height="72px">
    <div class="header">
      <el-image
        :src="require('../../assets/images/jsterp.svg')"
        style="width:156px;height:49px"
        fit="fill"
      />
      <el-row class="menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#333"
          active-text-color="#2263E6"
          @select="menuClick"
        >
          <el-menu-item index="one">{{ getlang('Home') }}</el-menu-item>
          <el-menu-item index="second">{{ getlang('CoreCompetitiveness') }}</el-menu-item>
          <el-menu-item index="third">{{ getlang('Product') }}</el-menu-item>
          <el-menu-item index="fourth">{{ getlang('ContactUs') }}</el-menu-item>
          <el-submenu index="lang">
            <template slot="title">
              <i :class="'national-flag-big '+country" />
            </template>
            <el-menu-item index="la-cn" :class="{'actives':actives('cn')}">
              <i class="national-flag cn" />
              <span class="lang-lable">简体中文</span>
            </el-menu-item>
            <el-menu-item index="la-en" :class="{'actives':actives('en')}">
              <i class="national-flag en" />
              <span class="lang-lable">English</span>
            </el-menu-item>
            <el-menu-item index="la-th" :class="{'actives':actives('th')}">
              <i class="national-flag th" />
              <span class="lang-lable">ไทย</span>
            </el-menu-item>
            <el-menu-item index="la-vn" :class="{'actives':actives('vn')}">
              <i class="national-flag vn" />
              <span class="lang-lable">Tiếng việt</span>
            </el-menu-item>
            <el-menu-item index="la-id" :class="{'actives':actives('id')}">
              <i class="national-flag id" />
              <span class="lang-lable">Indonesia</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
        <el-row class="btn-box">
          <el-button
            type="text"
            size="small"
            @click="jump(JSTURL.createcompany, country.toLocaleUpperCase())"
          >{{ getlang('Register') }}</el-button>
          <el-button type="primary" size="small" @click="jump(JSTURL.login)">{{ getlang('LogIn') }}</el-button>
        </el-row>
      </el-row>
    </div>
  </el-header>
</template>

<script>
import { setLanguageId } from '@/utils';
export default {
  name: 'ContactUs',
  props: {
    country: {
      type: String,
      default: 'cn'
    }
  },
  data() {
    return {
      activeIndex: 'one',
      CountryList: {
        cn: {
          'Home': '首页',
          'CoreCompetitiveness': '核心优势',
          'Product': '产品',
          'ContactUs': '联系我们',
          'Register': '注册',
          'LogIn': '登录'
        },
        en: {
          'Home': 'Home',
          'CoreCompetitiveness': 'Core competitiveness',
          'Product': 'Product',
          'ContactUs': 'Contact Us',
          'Register': 'Register',
          'LogIn': 'Log in'
        },
        th: {
          'Home': 'หน้าแรก',
          'CoreCompetitiveness': 'ฟังชั่นการใช้งาน',
          'Product': 'ผลิตภัณฑ์',
          'ContactUs': 'ติดต่อเรา',
          'Register': 'ลงทะเบียน',
          'LogIn': ' เข้าสู่ระบบ '
        },
        vn: {
          'Home': 'Trang chủ',
          'CoreCompetitiveness': 'Giá trị cốt lõi',
          'Product': 'Sản phẩm',
          'ContactUs': 'Liên hệ chúng tôi',
          'Register': 'Đăng ký',
          'LogIn': 'Đăng nhập '
        },
        id: {
          'Home': 'Beranda',
          'CoreCompetitiveness': 'Nilai kompetitif',
          'Product': 'Produk',
          'ContactUs': 'Hubungi kami',
          'Register': 'Daftar',
          'LogIn': 'Masuk'
        }
      }
    };
  },
  computed: {
    getlang() {
      return (id) => {
        return this.CountryList[this.country][id];
      };
    },
    actives() {
      return (id) => {
        return this.country === id;
      };
    }
  },
  methods: {
    jump(src, LanguageId) {
      if (LanguageId) {
        setLanguageId(LanguageId);
        window.location.href = src;
      } else {
        window.location.href = src + `?${this.$route.name.toUpperCase()}`;
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        const path = `/${i.split('-')[1]}`;
        if (path !== this.$route.path) {
          this.$router.push({
            path
          });
          return false;
        } else {
          document.querySelector('#one').scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.lang-lable {
  margin-left: 10px;
}

.header-bj {
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 1px 10px 0 rgba(0, 0, 0, 0.04),
    0 0 4px -1px rgba(0, 0, 0, 0.08);

  .header {
    width: 1200px;
    padding: 10px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 auto;

    .menu {
      display: flex;
      align-items: center;

      /deep/ .el-menu--horizontal {
        > .el-menu-item {
          font-weight: 400;
          height: 50px;
          line-height: 50px;

          &:hover {
            color: #2263e6 !important;
          }
        }

        > .el-submenu {
          .el-submenu__title {
            height: 50px;
            line-height: 50px;
          }
        }
      }

      .el-menu.el-menu--horizontal {
        border-color: transparent;
      }
    }
  }
}

.national-flag {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/sprite.svg");
  background-repeat: no-repeat;
  vertical-align: middle;
}

.national-flag.vn {
  background-position: -137px -15px;
}

.national-flag.ES {
  background-position: -77px -15px;
}

.national-flag.KR {
  background-position: -20px -15px;
}

.national-flag.cn {
  background-position: -107px -15px;
}

.national-flag.en {
  background-position: -47px -15px;
}

.national-flag.th {
  background-position: -167px -15px;
}
.national-flag.id {
  width: 19px;
  background-position: -193px -15px;
}
.national-flag-big {
  display: inline-block;
  width: 33px;
  height: 23px;
  background-image: url("../../assets/images/sprite_big.svg");
  background-repeat: no-repeat;
  vertical-align: middle;
}

.national-flag-big.vn {
  background-position: -167px -12px;
}

.national-flag-big.ES {
  background-position: -85px -11px;
}

.national-flag-big.KR {
  background-position: -6px -11px;
}

.national-flag-big.cn {
  background-position: -126px -10px;
}

.national-flag-big.en {
  background-position: -43px -11px;
}

.national-flag-big.th {
  background-position: -202px -11px;
}

.national-flag-big.id {
  width: 33px;
  height: 23px;
  background-position: -239px -11px;
}
</style>
