<template>
  <div>
    找不到哦
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
};
</script>

<style lang="scss" scoped>

</style>
