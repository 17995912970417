<template>
  <div class="pages">
    <jst-header country="en" />
    <el-main id="one">
      <jst-banner />
      <el-row id="second" class="panel">
        <h2 class="title">Core competitiveness</h2>
        <el-row>
          <el-col :span="12">
            <div class="advantage yuan-box" style="border-bottom-width:1px">
              <h4>Multiple functions</h4>
              <p>
                JSTERP,Support free trial, no limit to the number of users.
                <br>Support cross platform multi store, unified inventory management,
                <br>Check orders intelligently,Efficient distribution,Order management
              </p>
              <div class="advantage-menu">
                <span>Order</span>
                <span>Warehouse</span>
                <span>Commodity</span>
                <span>Distribution</span>
                <span>Report</span>
                <span>Supply chain</span>
                <span>Procurement</span>
                <span>Inventory</span>
                <span>After-sales</span>
                <span>Finacial</span>
                <span>Customer</span>
                <span>Third-party Warehouse</span>
              </div>
              <img src="../../assets/images/advantages_icon_01.png">
              <span class="yuan" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;border-bottom-width:1px">
              <h4>Fast system</h4>
              <p>
                Fifteen years of technology precipitation, with an average daily verification of hundreds of millions
                of orders,
                <br>Stable deployment framework to provide customers with continuous and stable system services
              </p>
              <div class="advantage-menu">
                <span>Advanced framework</span>
                <span>High configuration cloud service</span>
                <span>No sense of upgrade</span>
              </div>
              <img src="../../assets/images/advantages_icon_02.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage">
              <h4>Good service</h4>
              <p>
                We have a perfect implementation service mechanism
                <br>Professional system and on-site planning services
                <br>Customer long-term Endurance service
              </p>
              <div class="advantage-menu">
                <span>Service from front to back</span>
                <span>Guide users until they master</span>
                <span>Uninterrupted on-site customer service</span>
              </div>
              <img src="../../assets/images/advantages_icon_03.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;">
              <h4>Cut costs</h4>
              <p>
                Improve warehouse efficiency and reduce labor costs;
                <br>Improved delivery accuracy, reducing the cost of wrong delivery and missed cost;
                <br>The degree of informatization is improved, and the cost of consumables is reduced.
              </p>
              <div class="advantage-menu">
                <span>Reduce labor costs</span>
                <span>Reduce the cost of wrong delivery and missed cost</span>
                <span>Reduce the cost of consumables</span>
              </div>
              <img src="../../assets/images/advantages_icon_04.png">
            </div>
          </el-col>
        </el-row>
      </el-row>
      <el-row id="third" class="panel">
        <h2 class="title">Our products</h2>
        <el-row class="product-menu">
          <div class="list">
            <p>
              <i class="iconfont icon-dingdan" />
            </p>
            <p class="name">Orders management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-shangpin" />
            </p>
            <p class="name">Products management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-caigou1" />
            </p>
            <p class="name">Procurement management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-jingxiao" />
            </p>
            <p class="name">Warehouse management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kefu3" />
            </p>
            <p class="name">After-sales management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-disanfangcangku" />
            </p>
            <p class="name">Third-party warehouse</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kucun1" />
            </p>
            <p class="name">Inventory management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-baobiao1" />
            </p>
            <p class="name">Report management</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-PDApandian" />
            </p>
            <p class="name">
              PDA
              <br>handheld
            </p>
          </div>
        </el-row>
      </el-row>
      <el-row class="product-features-box">
        <el-row class="panel">
          <h2 class="title Ffff">Core functions</h2>
          <el-tabs
            v-model="activeName"
            @tab-click="tabClick"
            @mouseover.native="mouseOver"
            @mouseleave.native="mouseLeave"
          >
            <el-tab-pane label="OMS" name="1">
              <dl>
                <dt>Order management</dt>
                <dd>● Real-time synchronization of orders, easy management of multiple platforms</dd>
                <dd>● Batch review of orders, intelligent identification of abnormal orders</dd>
                <dd>● Synchronously update and modify orders, intercept exceptions in time</dd>
                <dd>● Automatically split and merge orders</dd>
                <dd>● Gift delivery</dd>
                <dd>● Match express intelligently</dd>
              </dl>
              <img class="product-features-img" src="../../assets/images/oms_en.png">
            </el-tab-pane>
            <el-tab-pane label="WMS" name="2">
              <dl>
                <dt>Warehouse management</dt>
                <dd>● Multi-platform, multi-store inventory, real-time synchronization</dd>
                <dd>● Precise inventory, out of stock warning and not oversold</dd>
                <dd>● Invoicing inventoryapparent to the eye.</dd>
                <dd>● Handheld PDA paperless operation</dd>
                <dd>● Optimal picking path</dd>
                <dd>● Support employee performance appraisal performance appraisal</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/wms_en.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
            <el-tab-pane label="Multi platform" name="3">
              <dl>
                <dt>Multi platform</dt>
                <dd>
                  ● Docking with multiple well-known e-commerce platforms，such as lazada、shopee、tokopedia、shopify. All
                  orders are processed uniformly to improve efficiency
                </dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/dpt.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-row>
      <el-row class="panel" style="margin-top:50px">
        <div>
          <el-image :src="require('../../assets/images/gz.png')" style="width:780px;height:424px;" />
          <dl class="product-con" style="padding-top:0px;margin-top:-50px">
            <p>Multiple smart rules to speed up order processing</p>
            <dt>Smart filter rules</dt>
            <dd>
              After the order is downloaded, a variety of rules can be configured to classify the order in advance to
              facilitate subsequent batch modification and review
            </dd>
            <dt>Intelligent selection of logistics rules</dt>
            <dd>According to the logistics matching rules, intelligently match the optimal logistics provider</dd>
            <dt>Sub-storage rules intelligently</dt>
            <dd>
              The system can intelligently match the delivery warehouse according to the distribution of goods and
              regional distribution
            </dd>
            <dt>Gift rules</dt>
            <dd>
              According to the activity rules, the merchants intelligently give corresponding gifts according to the
              order to improve customer satisfaction
            </dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>Multiple picking strategies to achieve efficient warehousing operations</p>
            <dd>Specify multiple picking strategies at the same time</dd>
            <dd>Optimize order structure</dd>
            <dd>Maximize work efficiency</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/cc.png')"
            style="width:780px;height:424px; "
          />
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/bb.png')" style="width:780px;height:424px;" />
          <dl class="product-con">
            <p>Accurate operation analysis of multi-dimensional reports</p>
            <dd>Provide reports on the dimensions of products, orders, and stores to improve the main operation</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>PDA function support throughout the process</p>
            <dd>Provide PDA operation support throughout the process to improve the accuracy of the operation</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/pda.png')"
            style="width:780px;height:424px;"
          />
        </div>
      </el-row>
      <el-row class="service-progress">
        <div class="container">
          <h2 class="title">Service Process</h2>
          <div class="service-progress-img">
            <img src="../../assets/images/process.png" alt width="100%">
          </div>
          <div class="service-progress-content">
            <div class="item item-1">
              <p>On-site communication</p>
              <p>
                On-site investigation of customer business
                <br>patterns and business models
              </p>
            </div>
            <div class="item item-2">
              <p>formulate an overall plan</p>
              <p>
                Warehousing planning and transformation,
                <br>standard procedures, hardware procurement,
                <br>implementation schedule planning, etc.
              </p>
            </div>
            <div class="item item-3">
              <p>Staff training</p>
              <p>
                Unified training for related
                <br>departments involving ERP system
              </p>
            </div>
            <div class="item item-4">
              <p>Data initialization and sorting</p>
              <p>
                Sort out accounts, migrate historical data
                <br>and ensure data consistency
              </p>
            </div>
            <div class="item item-5">
              <p>Warehouse space consolidation</p>
              <p>Inventory check, arrange warehouse reasonably according to process planning</p>
            </div>
            <div class="item item-6">
              <p>Equipment and process debugging</p>

              <p>
                Hardware equipment and overall system
                <br>function process test
              </p>
            </div>
            <div class="item item-7">
              <p>Online trial run</p>
              <p>
                Negotiate the right time and switch the system
                <br>to ensure a smooth transition
              </p>
            </div>
            <div class="item item-8">
              <p>Process solidification formal operation</p>
              <p>
                Adjust the solution and confirm the formal
                <br>operation of the final standard business process
              </p>
            </div>
          </div>
        </div>
      </el-row>
      <el-row id="fourth" class="panel">
        <h2 class="title">Contact us</h2>
        <contact-us country="en" />
      </el-row>
    </el-main>
    <!-- <el-footer class="j-footer" height="auto">
      <div class="panel" style="padding:20px 0">
        <div class="foot-con">
          <div class="foot-con-header">management</div>
          <p>Company name: Shanghai Shangyi Network Technology Co., Ltd</p>
          <p>Address: Room 161, 1st Floor, Building 2, No. 68, Guangming Central Road, Zhuangxing Town, Fengxian District, Shanghai</p>
          <p>Advertising license: 91310120MA1HMDJL2N</p>
        </div>
      </div>
    </el-footer> -->
  </div>
</template>

<script>
import jstBanner from './moudel/banners';
import contactUs from '../../component/contactUs';
import jstHeader from '../../component/header';
export default {
  name: 'App',
  components: {
    jstBanner, contactUs, jstHeader
  },
  data() {
    return {
      activeName: '1',
      activeIndex: 'one'
    };
  },
  mounted() {
    this.timer = setInterval(this.croeFunction, 5000);
  },
  methods: {
    mouseOver() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseLeave() {
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    croeFunction() {
      let num = Number(this.activeName) + 1;
      if (num > 3) {
        num = 1;
      }
      this.activeName = String(num);
    },
    tabClick() {
      clearInterval(this.timer);
      this.timer = null;
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        this.$router.push({
          path: `/${i.split('-')[1]}`
        });
        return false;
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-link.el-link--default {
  color: #333;
  font-size: 16px;
}

.product-features-img {
  width: 748px;
  height: auto;
}

.pages {
  min-height: 100%;
  overflow: hidden;

  h2.title {
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 55px;

    &::after {
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 2px;
      width: 64px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
        no-repeat center center;
    }
  }

  .header-bj {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06),
      0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 0 4px -1px rgba(0, 0, 0, 0.08);

    .header {
      width: 1200px;
      padding: 10px 20px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 auto;

      .menu {
        display: flex;
        align-items: center;

        /deep/ .el-menu--horizontal {
          > .el-menu-item {
            font-weight: 400;
            height: 50px;
            line-height: 50px;

            &:hover {
              color: #2263e6 !important;
            }
          }

          > .el-submenu {
            .el-submenu__title {
              height: 50px;
              line-height: 50px;
            }
          }
        }

        .el-menu.el-menu--horizontal {
          border-color: transparent;
        }
      }
    }
  }

  .panel {
    width: 1200px;
    padding-top: 60px;
    margin: 0 auto;

    .advantage {
      background: #fff;
      padding: 40px 40px 0;
      height: 280px;
      box-sizing: border-box;
      border: 0 solid #f2f4f5;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease;

      &:hover {
        -webkit-box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      &.yuan-box {
        z-index: 2;

        .yuan {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #d8d8d8;
          position: absolute;
          right: -3px;
          bottom: -3px;
          z-index: 2;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        line-height: 20px;
        color: #999;
        margin-top: 16px;
        min-height: 80px;
      }

      .advantage-menu {
        background: #f9f9f9;
        min-height: 68px;
        color: #01a2f7;
        padding: 10px 18px;
        line-height: 24px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 22px;
        }
      }

      img {
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    .product-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        width: 150px;
        min-height: 90px;
        text-align: center;
        padding: 14px 0;

        &:nth-child(2n) {
          background: #eef7ff;
        }

        i {
          font-size: 26px;
          color: #2263e6;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        line-height: 18px;
      }
    }
  }

  .product-features-box {
    background-image: url("../../assets/images/product_features.b141f.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0d2796;
    height: 752px;
    margin-top: 20px;

    .el-tab-pane {
      border: 1px solid #657bd5;
      border-radius: 5px;
      background: rgba(101, 123, 213, 0.3);
      min-height: 426px;
      margin: 0 18px;
      padding-top: 34px;
      box-sizing: border-box;
    }
  }

  .service-progress {
    background: url("../../assets/images/process_bg.47772.png") no-repeat center;
    background-size: cover;
    padding-bottom: 132px;
    overflow: hidden;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      padding: 80px 18px 0;

      .block-cap {
        text-align: center;
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
          font-weight: normal;
          color: #333;
          margin: 70px 0;

          &::after {
            content: "";
            display: block;
            margin: 10px auto 0;
            height: 2px;
            width: 64px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
              no-repeat center center;
          }
        }
      }

      .service-progress-img {
        margin-top: 42px;
      }

      .service-progress-content {
        position: relative;

        .item {
          position: absolute;
          text-align: center;

          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4 {
            top: -310px;
            left: -18px;
          }

          &.item-5,
          &.item-6,
          &.item-7,
          &.item-8 {
            top: 10px;
            left: 982px;
          }

          &.item-5 {
            width: 305px;
          }

          &.item-2 {
            left: 290px;
          }

          &.item-3 {
            left: 657px;
          }

          &.item-5 {
            left: 944px;
          }

          &.item-4 {
            width: 305px;
            left: 933px;
          }

          &.item-6 {
            left: 640px;
          }

          &.item-7 {
            left: 295px;
          }

          &.item-8 {
            left: -50px;
          }

          p:first-child {
            font-size: 16px;
            color: #117be5;
            line-height: 26px;
            font-weight: bold;
          }

          p:last-child {
            font-size: 14px;
            color: #666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.product-con {
  width: 35%;
  padding-top: 40px;

  p {
    font-size: 30px;
    color: #2263e6;
    margin-bottom: 30px;
    line-height: 30px;
  }

  dt {
    margin-top: 10px;
    font-size: 16px;
    color: #2263e6;
    margin-bottom: 0px;
  }

  dd {
    font-size: 14px;
    color: #666;
  }
}

dl {
  vertical-align: top;
  width: 356px;
  padding-left: 28px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  dt {
    font-size: 30px;
    margin-bottom: 26px;
    color: #fff;
  }

  dd {
    line-height: 1.6em;
    padding: 6px 0;
    font-size: 14px;
    color: #cbcbcb;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__nav-scroll {
      overflow: hidden;
    }

    .el-tabs__item {
      font-size: 20px;
      color: #fff;
      height: 66px;
      padding: 0;

      &::after {
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
      }

      &.is-active::after {
        border-bottom: 8px solid #657bd5;
      }
    }
  }
}
</style>
