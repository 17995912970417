<template>
  <div class="pages">
    <jst-header country="cn" />
    <el-main id="one" style="padding-bottom:10px">
      <jst-banner />
      <el-row id="second" class="panel">
        <h2 class="title">核心优势</h2>
        <el-row>
          <el-col :span="12">
            <div class="advantage yuan-box" style="border-bottom-width:1px">
              <h4>功能多</h4>
              <p>
                JSTERP, 支持免费试用, 不限使用人数。
                <br>支持跨平台多店铺, 库存统一管理,
                <br>智能审单, 高效配货, 订单管理
              </p>
              <div class="advantage-menu">
                <span>订单管理</span>
                <span>仓储管理</span>
                <span>商品管理</span>
                <span>分销管理</span>
                <span>报表管理</span>
                <span>供应链管理</span>
                <span>采购管理</span>
                <span>库存管理</span>
                <span>售后管理</span>
                <span>财务管理</span>
                <span>客户管理</span>
                <span>第三方仓储</span>
              </div>
              <img src="../../assets/images/advantages_icon_01.png">
              <span class="yuan" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;border-bottom-width:1px">
              <h4>系统快</h4>
              <p>
                十五年技术沉淀,日均上亿单量验证，
                <br>稳定的部署框架给客户提供持续稳定的系统服务
              </p>
              <div class="advantage-menu">
                <span>框架先进</span>
                <span>高配云服务</span>
                <span>无感升级</span>
              </div>
              <img src="../../assets/images/advantages_icon_02.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage">
              <h4>服务好</h4>
              <p>
                我们有完善的实施服务机制
                <br>专业的系统、现场规划服务
                <br>客户长期的续航服务
              </p>
              <div class="advantage-menu">
                <span>从前到后服务</span>
                <span>教会为止</span>
                <span>不间断现场客服</span>
              </div>
              <img src="../../assets/images/advantages_icon_03.png">
            </div>
          </el-col>
          <el-col :span="12">
            <div class="advantage" style="border-left-width:1px;">
              <h4>成本省</h4>
              <p>
                仓库作业效率提升，降低人工成本 ；
                <br>发货准确率提升，降低错发漏发成本；
                <br>信息化程度提升，降低耗材成本。
              </p>
              <div class="advantage-menu">
                <span>人工成本降低</span>
                <span>错发漏发成本降低</span>
                <span>耗材成本降低</span>
              </div>
              <img src="../../assets/images/advantages_icon_04.png">
            </div>
          </el-col>
        </el-row>
      </el-row>
      <el-row id="third" class="panel">
        <h2 class="title">我们的产品</h2>
        <el-row class="product-menu">
          <div class="list">
            <p>
              <i class="iconfont icon-dingdan" />
            </p>
            <p class="name">订单</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-shangpin" />
            </p>
            <p class="name">商品管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-caigou1" />
            </p>
            <p class="name">采购管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-jingxiao" />
            </p>
            <p class="name">仓储管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kefu3" />
            </p>
            <p class="name">售后管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-disanfangcangku" />
            </p>
            <p class="name">三方仓管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-kucun1" />
            </p>
            <p class="name">库存管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-baobiao1" />
            </p>
            <p class="name">报表管理</p>
          </div>
          <div class="list">
            <p>
              <i class="iconfont icon-PDApandian" />
            </p>
            <p class="name">PDA手持</p>
          </div>
        </el-row>
      </el-row>
      <el-row class="product-features-box">
        <el-row class="panel">
          <h2 class="title Ffff">核心功能</h2>
          <el-tabs
            v-model="activeName"
            @tab-click="tabClick"
            @mouseover.native="mouseOver"
            @mouseleave.native="mouseLeave"
          >
            <el-tab-pane label="订单管理(OMS)" name="1">
              <dl>
                <dt>订单管理</dt>
                <dd>● 订单实时同步，多平台方便管理</dd>
                <dd>● 订单批量审核，智能甄别异常订单</dd>
                <dd>● 同步更新修改订单，及时拦截异常</dd>
                <dd>● 自动拆分、合并订单</dd>
                <dd>● 赠品配送</dd>
                <dd>● 智能匹配快递</dd>
              </dl>
              <img class="product-features-img" src="../../assets/images/oms_cn.png">
            </el-tab-pane>
            <el-tab-pane label="仓储管理(WMS)" name="2">
              <div>
                <dl>
                  <dt>仓储管理</dt>
                  <dd>● 多平台、多店铺库存，实时同步</dd>
                  <dd>● 精准库存，缺货预警不超卖</dd>
                  <dd>● 进销库存一目了然</dd>
                  <dd>● 手持PDA全仓无纸化操作</dd>
                  <dd>● 拣货路径最优化</dd>
                  <dd>● 支持员工绩效考核</dd>
                </dl>
                <img
                  class="product-features-img"
                  src="../../assets/images/wms_cn.png"
                  style="margin-top: -23px;"
                >
              </div>
            </el-tab-pane>
            <el-tab-pane label="多平台" name="3">
              <dl>
                <dt>多平台</dt>
                <dd>● 对接lazada、shopee、tokopedia、shopify等多个知名电商平台，所有的订单统一处理提升效率</dd>
              </dl>
              <img
                class="product-features-img"
                src="../../assets/images/dpt.png"
                style="margin-top: -23px;"
              >
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/gz.png')" style="width:780px;height:424px;" />
          <dl class="product-con">
            <p>多种智能规则加速订单处理</p>
            <dt>智能筛选规则</dt>
            <dd>订单下载之后,可以配置多种规则,提前将订单进行分类,方便后续批量修改、审理</dd>
            <dt>智能选物流规则</dt>
            <dd>根据物流匹配规则,智能匹配最优物流商</dd>
            <dt>智能分仓规则</dt>
            <dd>系统可以智能根据商品分布、区域分布智能匹配发货仓库</dd>
            <dt>赠品规则</dt>
            <dd>商家根据活动规则,智能根据订单赠送对应的赠品,提高客户满意度</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>多种拣货策略实现高效仓储作业</p>
            <dd>同时指定多种拣货策略</dd>
            <dd>优化订单作业结构</dd>
            <dd>最大化提升作业效率</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/cc.png')"
            style="width:780px;height:424px; "
          />
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <el-image :src="require('../../assets/images/bb.png')" style="width:780px;height:424px;" />
          <dl class="product-con">
            <p>多维度报表精准运营分析</p>
            <dd>提供商品、订单、店铺维度的报表,主力运营的提升</dd>
          </dl>
        </div>
      </el-row>
      <el-row class="panel">
        <div>
          <dl class="product-con">
            <p>全程PDA功能支持</p>
            <dd>提供全程PDA作业支持,提升作业准确率</dd>
          </dl>
          <el-image
            :src="require('../../assets/images/pda.png')"
            style="width:780px;height:424px;"
          />
        </div>
      </el-row>
      <el-row class="service-progress">
        <div class="container">
          <h2 class="title">服务流程</h2>
          <div class="service-progress-img">
            <img src="../../assets/images/process.png" alt width="100%">
          </div>
          <div class="service-progress-content">
            <div class="item item-1">
              <p>进场沟通</p>
              <p>
                现场调研客户商业
                <br>形态及业务模式
              </p>
            </div>
            <div class="item item-2">
              <p>整体方案制定</p>
              <p>
                仓储规划改造、标准流程、
                <br>硬件采购、实施进度规划等
              </p>
            </div>
            <div class="item item-3">
              <p>人员培训</p>
              <p>
                涉及ERP系统的
                <br>相关部门统一培训
              </p>
            </div>
            <div class="item item-4">
              <p>数据初始化整理</p>
              <p>
                账号梳理，迁移历史数据
                <br>并保证资料的一致性
              </p>
            </div>
            <div class="item item-5">
              <p>库区整理</p>
              <p>
                库存盘点，依照流程
                <br>规划合理布置仓库
              </p>
            </div>
            <div class="item item-6">
              <p>设备及流程调试</p>
              <p>
                硬件设备及整体系统
                <br>功能流程测试
              </p>
            </div>
            <div class="item item-7">
              <p>上线试运行</p>
              <p>
                协商合适时机，切换系统
                <br>确保平稳过渡
              </p>
            </div>
            <div class="item item-8">
              <p>流程固化 正式运行</p>
              <p>
                调整解决方案，确定最终
                <br>标准业务流程正式运行
              </p>
            </div>
          </div>
        </div>
      </el-row>
      <el-row id="fourth" class="panel">
        <h2 class="title">联系我们</h2>
        <contact-us />
      </el-row>
    </el-main>
    <!-- <el-footer class="j-footer" height="auto">
      <div class="panel" style="padding:20px 0">
        <div class="foot-con">
          <div class="foot-con-header">经营</div>
          <p>公司名称：上海尚义网络科技有限公司</p>
          <p>地址：上海市奉贤区庄行镇光明中心路68号2幢1层161室</p>
          <p>经营广告许可证：91310120MA1HMDJL2N</p>
        </div>
      </div>
    </el-footer> -->
  </div>
</template>

<script>
import jstBanner from './moudel/banners';
import contactUs from '../../component/contactUs';
import jstHeader from '../../component/header';
export default {
  name: 'App',
  components: {
    jstBanner,
    contactUs,
    jstHeader
  },
  data() {
    return {
      timer: '',
      activeName: '1',
      activeIndex: 'one'
    };
  },
  mounted() {
    this.timer = setInterval(this.croeFunction, 5000);
  },
  methods: {
    mouseOver() {
      clearInterval(this.timer);
      this.timer = null;
    },
    mouseLeave() {
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    croeFunction() {
      let num = Number(this.activeName) + 1;
      if (num > 3) {
        num = 1;
      }
      this.activeName = String(num);
    },
    tabClick() {
      clearInterval(this.timer);
      this.timer = null;
      if (!this.timer) {
        this.timer = setInterval(this.croeFunction, 5000);
      }
    },
    menuClick(i) {
      if (i.indexOf('la-') === 0) {
        this.$router.push({
          path: `/${i.split('-')[1]}`
        });
        return false;
      }
      document.querySelector(`#${i}`).scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-link.el-link--default {
  color: #333;
  font-size: 16px;
}

.product-features-img {
  width: 748px;
  height: auto;
}

.pages {
  min-height: 100%;
  overflow: hidden;

  h2.title {
    font-size: 30px;
    color: #333;
    margin-bottom: 40px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 55px;

    &::after {
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 2px;
      width: 64px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
        no-repeat center center;
    }
  }

  .panel {
    width: 1200px;
    padding-top: 80px;
    margin: 0 auto;

    .advantage {
      background: #fff;
      padding: 40px 40px 0;
      height: 280px;
      box-sizing: border-box;
      border: 0 solid #f2f4f5;
      position: relative;
      z-index: 1;
      transition: all 0.3s ease;

      &:hover {
        -webkit-box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.1);
        z-index: 2;
      }

      &.yuan-box {
        z-index: 2;

        .yuan {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #d8d8d8;
          position: absolute;
          right: -3px;
          bottom: -3px;
          z-index: 2;
        }
      }

      h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: normal;
      }

      p {
        line-height: 20px;
        color: #999;
        margin-top: 16px;
      }

      .advantage-menu {
        background: #f9f9f9;
        min-height: 68px;
        color: #01a2f7;
        padding: 10px 18px;
        line-height: 24px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 22px;
        }
      }

      img {
        position: absolute;
        right: 40px;
        top: 40px;
      }
    }

    .product-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list {
        width: 150px;
        text-align: center;
        padding: 14px 0;

        &:nth-child(2n) {
          background: #eef7ff;
        }

        i {
          font-size: 26px;
          color: #2263e6;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
      }
    }
  }

  .product-features-box {
    background-image: url("../../assets/images/product_features.b141f.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #0d2796;
    height: 752px;
    margin-top: 20px;

    .el-tab-pane {
      border: 1px solid #657bd5;
      border-radius: 5px;
      background: rgba(101, 123, 213, 0.3);
      min-height: 426px;
      margin: 0 18px;
      padding-top: 34px;
      box-sizing: border-box;
    }
  }

  .service-progress {
    background: url("../../assets/images/process_bg.47772.png") no-repeat center;
    background-size: cover;
    padding-bottom: 132px;
    overflow: hidden;

    .container {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
      padding: 80px 18px 0;

      .block-cap {
        text-align: center;
        margin-bottom: 65px;

        h2 {
          font-size: 30px;
          font-weight: normal;
          color: #333;
          margin: 70px 0;

          &::after {
            content: "";
            display: block;
            margin: 10px auto 0;
            height: 2px;
            width: 64px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAACCAIAAABnm03uAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkMwQTFDMEE5NkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkMwQTFDMEFBNkYyMzExRUE4NUZBRkEwQjRDNzM5QzdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzBBMUMwQTc2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzBBMUMwQTg2RjIzMTFFQTg1RkFGQTBCNEM3MzlDN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7d42xYAAAALUlEQVR42mJUaX/66ec/BkYGLIgJGxuNgZWLiyRGCpe92ET4mJmAvKENAAIMABlLBODR9yP2AAAAAElFTkSuQmCC)
              no-repeat center center;
          }
        }
      }

      .service-progress-img {
        margin-top: 42px;
      }

      .service-progress-content {
        position: relative;

        .item {
          position: absolute;
          text-align: center;

          &.item-1,
          &.item-2,
          &.item-3,
          &.item-4 {
            top: -310px;
            left: 56px;
          }

          &.item-5,
          &.item-6,
          &.item-7,
          &.item-8 {
            top: 10px;
            left: 982px;
          }

          &.item-2 {
            left: 340px;
          }

          &.item-3 {
            left: 682px;
          }

          &.item-4 {
            left: 974px;
          }

          &.item-6 {
            left: 672px;
          }

          &.item-7 {
            left: 348px;
          }

          &.item-8 {
            left: 34px;
          }

          p:first-child {
            font-size: 16px;
            color: #117be5;
            line-height: 26px;
            font-weight: bold;
          }

          p:last-child {
            font-size: 14px;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.product-con {
  width: 35%;
  padding-top: 40px;

  p {
    font-size: 30px;
    color: #2263e6;
    margin-bottom: 30px;
  }

  dt {
    margin-top: 10px;
    font-size: 16px;
    color: #2263e6;
    margin-bottom: 0px;
  }

  dd {
    font-size: 14px;
    color: #666;
  }
}

dl {
  vertical-align: top;
  width: 356px;
  padding-left: 28px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 36px;
  box-sizing: border-box;

  dt {
    font-size: 30px;
    margin-bottom: 26px;
    color: #fff;
  }

  dd {
    line-height: 1.6em;
    padding: 6px 0;
    font-size: 14px;
    color: #cbcbcb;
  }
}

/deep/ .el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .el-tabs__active-bar {
        display: none;
      }
    }

    .el-tabs__nav-scroll {
      overflow: hidden;
    }

    .el-tabs__item {
      font-size: 20px;
      color: #fff;
      height: 66px;
      padding: 0;

      &::after {
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
      }

      &.is-active::after {
        border-bottom: 8px solid #657bd5;
      }
    }
  }
}
</style>
